import React from 'react';
import {observer, inject} from "mobx-react";
import {Modal} from "react-bootstrap";
import {SuccessPage} from "components/form/success.page";
import {history} from "components/services/custom.router";

@inject("paymentModal", "formStore") @observer
class PaymentModal extends React.Component {
    constructor(props) {
        super(props);

        this.myRef = React.createRef();
    }
    render() {
        const s = this.props.paymentModal;
        const f = this.props.formStore;

        if (!f.paymentUrl) {
            return null;
        }

        const style = {
            "border" : "none",
            "width" : "370px",
            "minHeight" : "520px"
        }

        const log = () => {
            console.log("iframe load");
            try {
                console.log("iframe loaded", this.myRef.current.contentWindow.location.href);

                const url = this.myRef.current.contentWindow.location.href;

                if (url && url.indexOf('success') > -1) {
                    console.log("success");
                    f.iframeResult = "success";
                }

                if (url && url.indexOf('error') > -1) {
                    console.log("error");
                    f.iframeResult = "error";
                }

                if (url && url.indexOf('cancel') > -1) {
                    console.log("cancel");
                    f.iframeResult = "cancel";
                }
            }
            catch (e) {
                console.log("can't get iframe url");
                f.iframeResult = "tranzila";
            }


        }

        let body = "";

        switch (f.iframeResult) {
            case "error":
                body = <div>שגיאה, אנא נסו שוב</div>;
                break;
            case "success":
                body = <SuccessPage/>
                break;
            default:
                body = (<iframe ref={this.myRef} style={style} src={f.paymentUrl} onLoad={log}/>);
        }

        console.log("render payment modal", f.iframeResult);

        return (
            <Modal
                show={s.isOpen}
                onHide={() => {
                    s.closeModal();
                   // f.isSubmitted = true;
                   // history.push('/pcr-test/error');

                    switch (f.iframeResult) {
                        case "error":
                            //history.push('/pcr-test/error');
                            break;
                        case "success":
                            history.push(process.env.REACT_APP_BASE_ROUTE+'/success');
                            f.isSubmitted = true;
                            break;
                        default:
                    }


                }}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title />
                </Modal.Header>
                <Modal.Body>
                    <div className={"center"}>
                        {body}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export {PaymentModal}