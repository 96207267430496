import React from 'react';
import {observer} from "mobx-react";
import "./upload.control.scss";

@observer
class UploadControl extends React.Component {
    constructor(props) {
        super(props);

        this.myRef = React.createRef();
    }
    render() {
        if (!this.props.store) {
            return null;
        }

        const s = this.props.store;

        if (!s.selectedFile) {
            const css = "upload-control" + (!s.isValid ? " invalid" : "");

            return (
            <div>
                <div className={"ui-label"}>{s.title}</div>
                <div className={css}>
                    <div className={"btn-upload"} onClick={() => {
                        this.myRef.current.click();
                        console.log("ref",this.myRef.current.files);
                    }}>
                        <div className={"wrap"}>
                            <div className={"img-upload"}/>
                            <div className={"btn-text"}>העלה קובץ</div>
                            <div className={"description"}>{s.description}</div>
                        </div>
                    </div>
                </div>
                <input ref={this.myRef} type="file" name="file" style={{"visibility":"hidden"}} onChange={() => {
                    console.log("change", this.myRef.current.files);
                    s.uploadFile(this.myRef.current.files);
                }}/>
            </div>
            );
        }

        const iconProgress = (s.progress === 100) ? <div className={"icon-v"}/> : null;

        return (
            <div>
                <div className={"ui-label"}>{s.title}</div>
                <div className={"upload-control"}>
                    <div className={"wrap"}>
                        <div className={"row1"}>
                            <div className={"image-mask"}><div className={"photo-icon"}/>{iconProgress}</div>
                            <div className={"filename"}>{s.selectedFile}</div>
                        </div>

                        <div className={"row2"}>
                            <div className={"progress-bar"}>
                                <div className={"bar"} style={{"width":s.progress+"%"}}/>
                            </div>
                            <div className={"btn-cancel"} onClick={() => {s.cancelUpload();}}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

UploadControl.defaultProps = {
    store : null
}

export {UploadControl}