import React from 'react'

class SuccessPage extends React.Component {
    render() {
        return (
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <h1>הזמנתכם מאושרת!</h1>

                    <p>תודה שרכשתם מאתנו בדיקת קורונה (PCR)</p>
                    <p>
                        שימו לב, הבדיקה תתבצע כ-48 שעות לפני טיסת החזור לישראל במלון בו הנכם שוהים (אם המעבדה אינה מגיעה למלון – ניצור עמכם קשר במהלך 2 ימי העסקים הקרובים).</p>
                     <p>   כ-24 שעות לפני מועד הבדיקה תישלח אליכם הודעה ב- WhatsApp  או בדוא"ל ובה שעת הבדיקה המדויקת.</p>

                    <p>תוצאות הבדיקה יישלחו אליכם בהודעת דוא"ל עד סוף היום שלאחר ביצוע הבדיקה, כלומר יום לפני טיסתכם חזרה לישראל.</p>
                    <p>אנו מאחלים לכם חופשה מהנה!</p>

                </div>
            </div>
        )
    }
}

export {SuccessPage}