import React from 'react'
import {inject, observer} from "mobx-react";
import {FormField} from "./field";

@inject ("formStore") @observer
class PaymentForm extends React.Component {
    render() {
        const s = this.props.formStore;

        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <h2>פרטי המשלם
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <FormField field={s.ccName} maxLength={60}/>
                    </div>

                    <div className="form-group col-md-6">
                        <FormField field={s.ccEmail} maxLength={320}/>
                    </div>
                </div>

                <div className={"row"}>

                    <div className="form-group col-md-6">
                        <FormField field={s.ccPhone} maxLength={15}/>
                    </div>

                </div>

                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <h2>סה"כ לתשלום <span className={"price"}>{s.currency}{s.totalPrice}</span></h2>
                    </div>
                </div>
            </div>
        )
    }
}

export {PaymentForm}