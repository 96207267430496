import React from 'react';
import {FormField} from "./field";
import {Dropdown, Form} from "react-bootstrap";
import {UploadControl} from "./upload.control";
import {observer} from "mobx-react";

@observer
class PersonForm extends React.Component {
    render() {
        if (!this.props.person) {
            return null;

        }

        const s = this.props.person;

        const maleChecked = (s.gender === "Male");
        const femaleChecked = (s.gender === "Female");

        const adultChecked = (s.age === "Adult");
        const childChecked = (s.age === "Child");
        const infantChecked = (s.age === "Infant");

        return (
                <div className={"person"}>
                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <div className={"pass-header"}>
                                <div className={"avatar-passenger"}/>
                                <div className={"num"}>
                                    נוסע <span>{this.props.number}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-md-12"}>

                            <div className="row">
                                <div className="form-group col-md-6">
                                    <FormField field={s.lastName} direction={"ltr"} maxLength={32} onFocus={() => {
                                        s.copyData();
                                    }}/>
                                </div>

                                <div className="form-group col-md-6">
                                    <FormField field={s.middleName} direction={"ltr"} maxLength={32}/>
                                </div>
                            </div>

                            <div className={"row"}>

                                <div className="form-group col-md-6">
                                    <FormField field={s.firstName}  direction={"ltr"} maxLength={32}/>
                                </div>

                                <div className={"form-group col-md-6"}>
                                    <FormField field={s.birthDate}/>
                                </div>

                            </div>

                            <div className={"row"}>
                                <div className="form-group col-md-6">
                                    <label className={"ui-label"}>גיל</label>
                                    <div className={"row"}>
                                        <div className={"col-md-4"}>
                                            <Form.Check name={"age" + this.props.number} id={"age_adult_"+ this.props.number} type={"radio"} label={"Adult"} checked={adultChecked} onChange={() => {
                                                s.setAge("Adult");
                                            }}/>
                                        </div>
                                        <div className={"col-md-4"}>
                                            <Form.Check name={"age" + this.props.number} id={"age_child_"+ this.props.number} type={"radio"} label={"Child"} checked={childChecked} onChange={() =>{
                                                s.setAge("Child");
                                            }}/>
                                        </div>
                                        <div className={"col-md-4"}>
                                            <Form.Check name={"age" + this.props.number} id={"age_infant_"+ this.props.number} type={"radio"} label={"Infant"} checked={infantChecked} onChange={() => {
                                                s.setAge("Infant");
                                            }}/>
                                        </div>

                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <label className={"ui-label"}>מגדר</label>
                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <Form.Check name={"gender" + this.props.number} id={"gender_male_"+ this.props.number} type={"radio"} label={"Male"} checked={maleChecked} onChange={() =>{
                                                s.setGender("Male");
                                            }}/>
                                        </div>
                                        <div className={"col-md-6"}>
                                            <Form.Check name={"gender" + this.props.number} id={"gender_female_"+ this.props.number} type={"radio"} label={"Female"} checked={femaleChecked} onChange={() =>{
                                                s.setGender("Female");
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-md-6">
                                    <FormField field={s.passportNumber} maxLength={32}/>
                                </div>

                                <div className="form-group col-md-6">
                                    <FormField field={s.mobilePhone} maxLength={15}/>
                                </div>
                            </div>
                    </div>
                </div>
        );
    }
}

PersonForm.defaultProps = {
    person : null
}

export {PersonForm}