import access from 'safe-access';
import {observable, action, computed} from "mobx";
import {isBlankString, isEmailValid, isEnglishOnly, isPhoneNumberValid, isAlphaNumeric} from "../utils/validator";
import axios from 'axios';
import {makeid, convertPhoneNumber, convertDate} from "../utils/common";
import {FieldTypes, TextField, DateField, EmailField} from "stores/fields";
import moment from "moment";
import {Person} from "stores/person";

const DEFAULT_CONFIG = { headers: { "Content-Type": "application/json" } };
const MULTIPART_CONFIG = { headers: { "Content-Type": "multipart/form-data" } };
const URLENCODED_CONFIG = { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } }

function fixedEncodeURIComponent (str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
}

class FormStore {
    @observable customerName = new TextField("שם הלקוח (באנגלית)", true, "שם ושם משפחה");
    @observable email = new EmailField("דואר אלקטרוני", true);
    @observable mobilePhone = new TextField("טלפון נייד", true);

    // payment information
    @observable ccName = new TextField("שם מלא", true);
    @observable ccEmail = new EmailField("דואר אלקטרוני", true);
    @observable ccPhone = new TextField("טלפון נייד", true);

    @observable destination = "";


    @observable hotel =  new TextField("המלון בו תשהו (באנגלית)", true);
    @observable returnDate = new DateField("תאריך טיסת החזור", true);
    @observable orderPlace = new TextField("שם הסוכנות", true);


    @observable passengerCount = 1;

    @observable passengersArr = [1,2,3,4,5,6];

    @observable passengers = [];

    @observable isSubmitted = false;
    @observable destinationsData = null;
    @observable selectedDestination = null;
    @observable validationMessages = [];
    @observable serverTime = null;

    @observable ref = "";
    @observable prices = [];

    @observable paymentUrl;
    @observable iframeResult;

    @observable selectedDestinationObj = null;


    constructor(root) {
        this._root = root;
        this.passengers.push(new Person(this));

        this.fetchServerTime();
        this.fetchPrices();

        var qs = (function(a) {
            if (a == "") return {};
            var b = {};
            for (var i = 0; i < a.length; ++i)
            {
                var p=a[i].split('=', 2);
                if (p.length == 1)
                    b[p[0]] = "";
                else
                    b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
            }
            return b;
        })(window.location.search.substr(1).split('&'));

        console.log("params", qs);
        this.ref = qs['ref'];

        if (process.env.REACT_APP_DUMMY_DATA) {
            this.customerName.setValue("Test Name");
            this.email.setValue("test@test.com");
            this.mobilePhone.setValue("0555555555");

            this.ccName.setValue("Test Name");
            this.ccEmail.setValue("test@test.com");
            this.ccPhone.setValue("0555555555");

            this.hotel.setValue("test hotel");
            const date = new Date();
            date.setDate(date.getDate() + 7);
            this.returnDate.setValue(date);
        }

    }

    @action fetchServerTime() {
        const d = moment(new Date());
        const url = process.env.REACT_APP_API_URL + "/api/server-time";


        axios.get(url,DEFAULT_CONFIG)
            .then((res) => {
                console.log("serverTime", res.data.serverTime);
                this.serverTime = res.data.serverTime;


                const d2 = moment(this.serverTime);

                console.log("time difference",d2.diff(d));

            })
            .catch((e) =>{
                console.error(e);
            })
    }

    @action fetchPrices() {
        const url = process.env.REACT_APP_API_URL + "/api/prices";

        axios.get(url, DEFAULT_CONFIG)
            .then((res) => {
                console.log("fetchPrices", res.data);
                this.prices.replace(res.data);
            })
            .catch((e) =>{
                console.error(e);
            })
    }

    @computed get timeDifference() {
        if (!this.returnDate.value) {
            return null;
        }

        const d = moment (this.returnDate.value);
        const d2 = moment(this.serverTime);

        const diff = d.diff(d2,'hours');
        console.log("time difference",diff);
        return diff;
    }

    @computed get showDateAlert() {
        if (this.timeDifference === null) {
            return false;
        }

        return this.timeDifference < 56;
    }

    @computed get selectedDestinationName() {
        if (!this.selectedDestination) {
            return "";
        }

        return this.selectedDestination.n;
    }

    @computed get isFormValid() {
        let isValid = true;

        const allFields = [
            this.customerName,
            this.email,
            this.mobilePhone,
            this.returnDate,
            this.ccPhone,
            this.ccEmail,
            this.ccName
        ];

        allFields.map((f) => {
            if (!f.isValid) {
                isValid = false;
            }
        });

        this.passengers.forEach((p) => {
            if (!p.isFormValid) {
                isValid = false;
            }
        })

        if (isBlankString(this.destination) || isBlankString(this.hotel.value)) {
            return false;
        }


        if (!isEnglishOnly(this.hotel.value)) {
            isValid = false;
        }

        return isValid;
    }

    @action postForm() {
        const d = this.jsonData;

        console.log("jsonData", d);
        const url = process.env.REACT_APP_API_URL + "/api/pcr-form-full";

        this.iframeResult = "";
        this.isSubmitted = false;

        axios.post(url, d,DEFAULT_CONFIG)
            .then((res) => {
                console.log("res",res);


                if (this.showDateAlert) {
                    this._root.submitModal.openModal();
                    this.isSubmitted = true;
                } else {
                    const token = access(res, 'data.token');
                    const url = 'https://direct.tranzila.com/kaveich/iframenew.php?lang=il&sum=' + this.totalPriceInNIS
                        + '&currency=1&r1_token=' + token + '&email=' + fixedEncodeURIComponent(this.ccEmail.value)
                        + '&contact='+fixedEncodeURIComponent(this.ccName.value);
                    console.log("tranzila URL", url);

                    this.paymentUrl = url;
                    this._root.paymentModal.openModal();
                    //window.location.href = 'https://pay.tranzila.com/dakaa90';
                }



            })
            .catch((e) =>{
                console.error(e);
            })
    }

    @computed get jsonData() {

        const product = this.selectedDestinationObj;

        const form = {
            "customerName" : this.customerName.value,
            "email" : this.email.value,
            "mobilePhone" : convertPhoneNumber(this.mobilePhone.value),
            "passengerCount" : this.passengerCount,

            "destination"   : this.destination,
            "hotel"         : this.hotel.value,
            "returnDate"    : convertDate(this.returnDate.value),
            "ref"     : this.ref,

            "ccName" : this.ccName.value,
            "ccEmail" : this.ccEmail.value,
            "ccPhone" : this.ccPhone.value,

            "passengers" : this.passengers.map((p) => {
                return p.jsonData
            })
        }

        const d = {
            "form" : form,
            "selectedProduct" : product,
            "passengersCount" : this.passengers.length,
            "totalPrice"      : this.totalPrice,
            "totalPriceInNIS" : this.totalPriceInNIS,
            "dueDate" : this.showDateAlert
        }

        console.log("fullJSON", d);

        return d;
    }

    @observable setDestination (newValue) {
        this.destination = newValue;
    }

    @observable setDestinationObj (newValue) {
        console.log(newValue);
        this.selectedDestinationObj = newValue;
    }

    @observable setOrderPlace(newValue) {
        this.orderPlace = newValue;
    }

    @action updateArray() {
        console.log("updateArray");

        let diff = this.passengerCount - this.passengers.length;
        console.log("diff",diff);
        if (diff > 0) {
            for (let i=0;i<diff;i++) {
                this.passengers.push(new Person(this));
            }
        }

        if (diff < 0) {
           diff = Math.abs(diff);
           this.passengers.splice(this.passengers.length-diff,diff);
        }

        this.passengers.forEach((p) =>{
            p.copyData();
        })
    }

    @action fillPaymentData() {
        if (this.ccEmail.value === "" && isEmailValid(this.email.value)) {
            this.ccEmail.setValue(this.email.value);
        }

        if (this.ccPhone.value === "" && isPhoneNumberValid(this.mobilePhone.value)) {
            this.ccPhone.setValue(this.mobilePhone.value);
        }


    }
    @action validateForm() {
        console.log("validateForm");
        const messages = [];

        if (!isEmailValid(this.email.value)) {
            this.email.isValid = false;
        }

        if (!isEnglishOnly(this.customerName.value)) {
            this.customerName.isValid = false;
        }

        if (!isPhoneNumberValid(this.mobilePhone.value)) {
            this.mobilePhone.isValid = false;
        }

        if (isBlankString(this.orderPlace.value)) {
            this.orderPlace.isValid = false;
        }

        if (isBlankString(this.returnDate.value)) {
            this.returnDate.isValid = false;
        }

        if (!isEnglishOnly(this.hotel.value)) {
            this.hotel.isValid = false;
        }

        if (isBlankString(this.destination)) {
            messages.push('אנא בחרו את היעד אליו אתם טסים')
        }

        // payment form
        if (isBlankString(this.ccName.value)) {
            this.ccName.isValid = false;
        }

        if (!isEmailValid(this.ccEmail.value)) {
            this.ccEmail.isValid = false;
        }


        if (!isPhoneNumberValid(this.ccPhone.value)) {
            this.ccPhone.isValid = false;
        }

        this.passengers.map((p) => {
            p.validateForm();
        });

        this.validationMessages.replace(messages);
    }

    @computed get totalPrice() {
        if (this.selectedDestinationObj) {
            console.log("this.selectedDestinationObj",this.selectedDestinationObj);
            return this.selectedDestinationObj.price * this.passengers.length;
        }

        return 0;
    }

    @computed get totalPriceInNIS() {
        if (this.selectedDestinationObj) {
            return this.selectedDestinationObj.priceInNIS * this.passengers.length;
        }

        return 0;
    }

    @computed get currency() {
        return "€";
    }


}

export {FormStore,FieldTypes}