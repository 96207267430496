import React from 'react'
import {inject, observer} from "mobx-react";
import {Dropdown, Button, Form, Modal} from "react-bootstrap";
import {FormField} from "./field";
import {PersonForm} from './person.form';
import {PaymentForm} from "./payment.form";

const DateAlert = () => {
    return (
        <div className={"row"}>
            <br/><br/>
            <div className={"alert alert-warning"}>לקוחות יקרים,מועד טיסת החזור שבחרתם קרוב. אנא מלאו את הפרטים מטה ואנו נשוב אליכם במייל מיד לאחר קבלת תשובה מהמעבדה לגבי הזמינות והמחיר.</div>
        </div>

    )
}

@inject ("formStore","validationModal") @observer
class FullForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            validated : false
        }
    }
    render() {
        const s = this.props.formStore;

        const handleSubmit = (event) => {
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }

            this.setState({
                validated : true
            });

            s.validateForm();

            event.preventDefault();
            event.stopPropagation();
            //setValidated(true);


            if (!s.isFormValid) {
                this.props.validationModal.openModal();
                return;
            }

            s.postForm();
        };

        if (s.isSubmitted) {
            return (
                <div className="alert alert-success" role="alert">
                    הטופס נשלח בהצלחה, אנו נשוב אליכם בהקדם.
                </div>

            )
        }

        const dateAlert  = (s.showDateAlert) ? <DateAlert/> : "";
        const btnText = (s.showDateAlert) ? "שלח" : "המשך לתשלום";

        return (
            <div>
                <Form noValidate onSubmit={handleSubmit}>
        <div className={"row"}>
            <div className={"col-md-12"}>
                <h1>הזמנת בדיקות קורונה בחו&quot;ל</h1>
                <p>לקוחות יקרים,<br/>
                    אנא מלאו את הפרטים מטה, לאחר מכן תועברו לדף תשלום.
                </p>

                <p>לפני שאנו מתחילים, הנה כמה פרטים שחשוב לדעת:</p>
                <ul>
                    <li>בהתאם להנחיית משרד הבריאות, כל אדם ששהה מחוץ לישראל מעל 72 שעות (3 ימים) חייב להציג לחברת התעופה תוצאה שלילית של בדיקת קורונה טרם העלייה למטוס.</li>
                    <li>חובת הבדיקה חלה על כל הנוסעים – מחוסנים, מחלימים, ילדים ותינוקות (והכול בכפוף להנחיות משרד הבריאות).</li>
                    <li>בהזמנה דרכנו, אנחנו אחראים לכך שתקבלו את התוצאות בזמן.</li>
                    <li>מיד עם ביצוע התשלום תישלח אליכם קבלה בדוא"ל.</li>
                    <li>הודעת WhatsApp ובה שעת הבדיקה המדויקת תישלח אליכם בשעות אחה"צ/ערב ביום שלפני הבדיקה. במקרים בהם הבדיקה אינה מבוצעת במלון בו הנכם שוהים, תכלול ההודעה גם את מיקום נקודת הבדיקה של המעבדה.</li>
                    <li>תוצאות הבדיקה יישלחו אליכם לאותו המייל יום לאחר ביצוע הבדיקה, כלומר יום לפני טיסת החזור.</li>
                </ul>
            </div>

                <div className={"col-md-12"}>
                    <p><strong>בחר יעד:</strong></p>
                </div>

            {s.prices.map((priceObj, index) => {
                const dest = (priceObj.title) ? priceObj.title : (priceObj.destination + " – בדיקת קורונה בעלות €" + priceObj.price + " לאדם");
                return (
                    <div key={"dest-"+index} className={"form-group col-md-12"}>
                        <Form.Check name={"destination_field"}
                                    id={"destination_"+index}
                                    type={"radio"}
                                    label={dest}
                                    checked={s.destination === dest}
                                    onChange={() => {
                                        s.setDestination(dest);
                                        s.setDestinationObj(priceObj);
                                    }}/>
                    </div>
                )
            })}

            <div className={"col-md-12"}>
                <div>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <FormField field={s.customerName} maxLength={30}/>
                        </div>

                        <div className="form-group col-md-6">
                            <FormField field={s.email} maxLength={50} onBlur={() => {s.fillPaymentData();}}/>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className="form-group col-md-6">
                            <FormField field={s.mobilePhone} maxLength={15} onBlur={() => {s.fillPaymentData();}}/>
                        </div>

                        <div className="form-group col-md-6">
                            <label className={"ui-label"}>מספר הנוסעים</label>
                            <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    {s.passengerCount}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {s.passengersArr.map((item) => {
                                        return <Dropdown.Item key={"passenger" + item} onClick={() => {
                                            s.passengerCount = item;
                                            s.updateArray();
                                        }}>{item}</Dropdown.Item>
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>


                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            <FormField field={s.hotel} maxLength={25}/>
                        </div>

                        <div className={"col-md-6"}>
                            <FormField field={s.returnDate} maxLength={15}/>
                        </div>
                    </div>


                </div>


            </div>
        </div>
                {dateAlert}
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <br/><br/>
                </div>
                {s.passengers.map((item,index)=>{
                    return <PersonForm key={"person"+index} person={item} number={index+1} />
                })}
            </div>

            <div className={"row"}>
                <div className={"col-md-12"}>
                    <PaymentForm/>
                </div>
            </div>

                <div className={"row"}>

                    <div className={"col-md-10"}>
                        <br/><br/>
                    <p>מובהר כי קווי חופשה מהווה גורם מקשר בלבד ואינה אחראית לשימוש
                        שיעשה במידע או לאמצעי אבטחת המידע הננקטים על ידי המעבדה
                        אליה יועבר המידע. מילוי הפרטים ושליחות מהווה הסכמה להעברת
                        המידע אודות הנוסעים (לרבות קטינים ככל שכלולים בהזמנה).</p>
                        <p>עבור שירותים הניתן במלואם מחוץ לישראל - לרבות בדיקות קורונה בחו"ל,
                            דמי הביטול כפופים למדיניות הביטול של הספק <a href="https://www.kavei.co.il/GeneralInfo/Terms.aspx" target="_blank"><u>ולתנאים הכלליים</u></a> ועומדים על 100%,
                            ולא יינתן כל החזר כספי בגין אי מימוש השירות לאחר ביצוע ההזמנה.
                        </p>
                    </div>

                    <div className={"col-md-2"}>
                        <br/><br/>
                        <Button type={"submit"} variant="primary">{btnText} </Button>

                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-md-12"}><br/></div>
                </div>
                </Form>
        </div>);

    }
}

export {FullForm}