import React from 'react'

class ErrorPage extends React.Component {
    render() {
        return (
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <h1>הזמנתכם לא מאושרת!</h1>

                    <p>שגיאה בתהליך התשלום. אנא נסו מאוחר יותר</p>

                </div>
            </div>
        )
    }
}

export {ErrorPage}